'use client';

import cx from 'classnames';
import { useIntl } from 'react-intl';

import { getAllSitesEnabled } from '../../../../../core/Site/Site';
import { Select } from '../../../../../design-system/components/form/select/Select';
import { useCurrency } from '../../../../../shared/providers/currency/useCurrency';

import linkStyle from './../FooterLinkList.module.css';
import style from './FooterLinksCountries.module.css';

export function FooterLinksCountries() {
    const sitesEnabled = getAllSitesEnabled();
    const { formatMessage } = useIntl();
    const { currencies, changeCurrentCurrency, currentCurrency } = useCurrency();

    return (
        <>
            <span className={cx(linkStyle.linkTitle, 'font-m-extrabold')}>
                {formatMessage({ id: 'global.footer.links.sites' })}
            </span>
            <ul className="font-body-regular">
                {sitesEnabled.map((site) => {
                    const { country } = site;

                    return (
                        <li key={country}>
                            <a
                                className={cx(linkStyle.link, style.link)}
                                href={site.url}
                                lang={site.hrefLang}
                            >
                                {site.titleWithMarket}
                            </a>
                        </li>
                    );
                })}
            </ul>

            <Select
                className={style.selectWrapper}
                selectProps={{
                    'aria-label': formatMessage({ id: 'global.footer.currency.select.ariaLabel' }),
                }}
                options={currencies.map((currency) => {
                    return {
                        value: currency,
                        label: currency,
                    };
                })}
                selected={currentCurrency}
                setSelected={(currency) => {
                    changeCurrentCurrency(currency);
                }}
            />
        </>
    );
}
